/** @jsxImportSource @emotion/react */

import React, { useRef } from 'react'
import './App.scss'
import { css, Global } from '@emotion/react'

const backgroundColor = '#fff'
const subheadColor = '#FB00FF'
const breakpoints = [700, 480]
const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`)

type ContactUsSubject = 'inquiry' | 'donation' | 'volunteer'
interface FormData {
	subject: ContactUsSubject | undefined
	name: string | undefined
	contact: string | undefined
	message: string | undefined
}

function isContactUsSubject(value: string): value is ContactUsSubject {
	return ['inquiry', 'donation', 'volunteer'].includes(value)
}
function asContactUsSubject(value: string): ContactUsSubject {
	if (isContactUsSubject(value)) {
		return value
	}
	return 'inquiry'
}

const App: React.FunctionComponent<{}> = () => {
	const dialogRef = useRef<HTMLDialogElement>(null)
	const [formData, setFormData] = React.useState({} as FormData)
	const showDialog = () => {
		if (dialogRef.current) {
			dialogRef.current.showModal()
		}
	}

	return (
		<>
			<Global
				styles={css`
					h1,
					h2,
					h3 {
						color: ${subheadColor};
						&.secondary {
							color: #00f;
						}
					}
					body {
						background-color: ${backgroundColor};
						line-height: 1.4;
						a {
							color: inherit;
						}
					}
					button {
						font-size: 0.75em;
						font-weight: bold;
						background-color: #00f;
						color: #fff;
						border: none;
						border-radius: 10px;
						padding: 20px;
						height: 40px;
						display: flex;
						align-items: center;

						&:hover {
							background: #1a373b40;
							color: ${subheadColor};
						}
						&.secondary {
							background-color: #999;
						}
					}
					main,
					dialog {
						${mq[0]} {
							font-size: 165%;
						}
						font-size: 125%;
					}
					dialog {
						background-color: #f9faff;
						position: fixed;
						width: calc(100% - 80px);
						height: calc(100% - 80px);
						${mq[0]} {
							width: 62%;
							min-height: 62%;
						}

						border: 2px solid #00f;
						border-radius: 10px;
						padding: 20px;
						z-index: 1;
						box-shadow: 0 20px 50px #00000040;
						&::backdrop {
							backdrop-filter: blur(10px);
						}
					}
					select,
					textarea,
					input {
						font-size: 100%;
						border: none;
						border-radius: 5px;
						padding: 10px;
						background: #80808018;
					}
					select {
						appearance: none;
					}
					textarea {
						border-color: #80808080;
					}
					input {
					}
				`}
			/>
			<dialog ref={dialogRef}>
				<div
					className="dialog-content"
					css={css`
						height: 100%;
						position: relative;
						flex-grow: 1;
						display: flex;
						flex-direction: column;
					`}
				>
					<h3
						css={css`
							margin-top: 0;
						`}
					>
						Contact Form
					</h3>
					<div
						css={css`
							flex-grow: 1;
							display: flex;
							flex-direction: column;
						`}
					>
						<form
							name="contact"
							method="POST"
							data-netlify="true"
							css={css`
								flex-grow: 1;
								display: flex;
								flex-direction: column;
								gap: 20px;
								justify-items: stretch;

								.form-section {
									display: flex;
									flex-direction: column;
									align-items: stretch;
									justify-items: stretch;
									gap: 10px;
								}
								label {
									font-size: 70%;
									font-weight: bold;
									display: block;
									color: #333;
								}
							`}
						>
							<div
								css={css`
									display: none;
								`}
							>
								<input type="hidden" name="form-name" value="contact" />
								<label htmlFor="bot-field">Don't fill this out if you're human:</label>
								<input id="bot-field" name="bot-field" hidden />
							</div>
							<div className="form-section">
								<label htmlFor="form-subject">How can we help you?</label>
								<select
									name="form-subject"
									id="form-subject"
									value={formData.subject}
									onChange={(e) =>
										setFormData({ ...formData, subject: asContactUsSubject(e.target.value) })
									}
									required
								>
									<option value="inquiry">I'm interested in your services</option>
									<option value="donation">I'd like to make a donation</option>
									<option value="volunteer">I'd like to volunteer</option>
								</select>
							</div>
							<div className="form-section">
								<label htmlFor="form-contact-name">Your name</label>
								<input
									name="form-contact-name"
									id="form-contact-name"
									placeholder="Your name"
									required
								/>
							</div>
							<div className="form-section">
								<label htmlFor="form-contact-info">Where should we contact you?</label>
								<input
									name="form-contact-info"
									id="form-contact-info"
									placeholder="E-mail or phone number"
									required
								/>
							</div>
							<div
								className="form-section"
								css={css`
									flex-grow: 1;
								`}
							>
								<label htmlFor="form-message">Your message</label>
								<textarea
									id="form-message"
									name="form-message"
									css={css`
										flex-grow: 1;
									`}
									placeholder="Your message..."
									required
								/>
							</div>
							<div
								css={css`
									// border-top: 1px solid rgba(0, 0, 0, 0.16);
									display: flex;
									padding: 0;
									// margin-top: 20px;
									// padding-top: 20px;
								`}
							>
								<button
									className="secondary"
									type="button"
									onClick={() => dialogRef.current?.close()}
									css={css`
										margin-left: auto;
									`}
								>
									Cancel
								</button>
								<button
									autoFocus
									type="submit"
									css={css`
										margin-left: 8px;
									`}
								>
									Send
								</button>
							</div>
						</form>
					</div>
				</div>
			</dialog>
			<header
				css={css`
					height: 100vh;
					min-height: 650px;
					contain: layout;
					position: relative;
				`}
			>
				<div
					id="hero-image-shadow"
					css={css`
						position: absolute;
						top: -50%;
						left: -50%;
						width: 200%;
						height: 200%;
						opacity: 1;
						${mq[0]} {
							background-position: 40% 50%;
							opacity: 0.5;
							top: calc(-50%);
							left: calc(-50%);
							width: calc(200%);
							height: calc(200%);
						}
						background-size: cover;
						background-position: 40% 50%;
						pointer-events: none;
					`}
				/>
				<div
					id="hero-image"
					css={css`
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						position: absolute;
						background-size: cover;
						background-position: 40% 50%;
						${mq[0]} {
							background-position: 50% 50%;
							border-radius: 32px;
							top: 50px;
							left: 50px;
							right: 50px;
							bottom: 50px;
						}
					`}
				/>
				<div
					css={{
						display: 'flex',
						flexDirection: 'column',
						top: '2em',
						right: '2em',
						bottom: '2em',
						left: '2em',
						position: 'absolute',
					}}
				>
					<div css={{ flex: 1 }}></div>
					<div
						css={css`
							display: flex;
							margin: 0 auto;
						`}
					>
						<picture css={css``}>
							<source srcSet="images/logo.svg"></source>
							<img
								css={css`
									max-width: 100%;
								`}
								src="images/logo.png"
								alt="Nurture logl"
							/>
						</picture>
					</div>
					<h2
						css={{
							fontSize: '150%',
							color: '#FFF',
							textAlign: 'center',
							fontWeight: 'normal',
							textShadow: ['0 0 15px #000'],
							// fontVariantCaps: 'small-caps',
							fontFamily: 'YankeeBold',
						}}
					>
						Well being for first generation families
					</h2>
					<div css={{ flex: 1 }}></div>
				</div>
			</header>
			<main
				css={css`
					position: relative;
					
					padding: 0 30px;
					${mq[0]} {
						padding: 0 50px;
						}
					section {
						max-width: 1000px;
						margin-left: auto;
						margin-right: auto;
						}
					section h2 {
						font-size: 200%;
					},
					h2, h3, h4 {
						font-family: YankeeBold;
					},
					h4 {
						font-family: inherit;
						font-weight: normal;
						font-size: 75%;
					},
				`}
			>
				<section className="first" id="our-mission-and-vision">
					<div className="inner-container">
						<h2>Our Mission</h2>
						<p>
							<strong>Nurture</strong> encourages social-emotional well-being and provides accessible
							mental health services to young children and families. We are focused on supporting first
							generation preschool and elementary school students. We are a sliding scale clinic offering
							evaluation and consultation services.
						</p>
					</div>
				</section>
				<section id="our-services">
					<div className="inner-container">
						<h2
							css={css`
								margin-bottom: 0.5em;
							`}
						>
							Services
						</h2>
						<div
							css={{
								[mq[0]]: {
									fontSize: '80%',
								},
								ul: {
									marginTop: '0',
									padding: '20px',
								},
								li: {
									margin: '0.5em 0',
									'&:first-of-type': {
										marginTop: 0,
									},
								},
								[mq[0]]: {
									display: 'grid',
									gridTemplateColumns: 'repeat(2, 1fr)',
									columnGap: 40,
								},
							}}
						>
							<div className="first">
								<h3 css={{ marginBottom: 0, marginTop: 0 }}>Evaluation</h3>
								<ul css={{ fontSize: '80%', textTransform: 'capitalize' }}>
									<li>neurodevelopmental & neuropsychological evaluation</li>
									<li>attention deficit hyperactivity</li>
									<li>autism spectrum</li>
									<li>learning disorders (e.g., dyslexia)</li>
									<li>giftedness</li>
									<li>anxiety disorders</li>
									<li>neurodiverse students</li>
								</ul>
							</div>
							<div>
								<h3 css={{ marginBottom: 0, marginTop: 0 }}>Consultation</h3>
								<ul css={{ fontSize: '80%', textTransform: 'capitalize' }}>
									<li>parent coaching</li>
									<li>family consultation</li>
									<li>teacher & school team consultations</li>
									<li>navigating community resources</li>
									<li>orientation to SF Bay Area preschools & elementary schools</li>
									<li>public & private schools guidance</li>
									<li>learning plans</li>
									<li>504 plan & individualized education plans (IEP)</li>
									<li>educational planning</li>
									<li>school placement</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
				<section
					css={css`
						margin=top: 96;
					`}
				>
					<div
						className="inner-container"
						css={css`
							.action-bar {
								border: 2px solid #00f;
								border-radius: 10px;
								background-color: #f9faff;
								padding: 20px;
								display: flex;
								align-items: center;
								justify-items: stretch;
								gap: 20px;
								> button {
									margin-left: auto;
								}
								margin-top: 10px;
								margin-bottom: 10px;
								> p {
									margin-top: 0;
									margin-bottom: 0;
								}
							}
							.action-prompt {
								color: ${subheadColor};
								font-weight: bold;
							}
							button {
								text-wrap: nowrap;
							}
						`}
					>
						<div className="action-bar">
							<p className="action-prompt">I'd like to learn more about Nurture</p>
							<button
								className="big-button"
								onClick={() => {
									setFormData({ ...formData, subject: 'inquiry' })
									showDialog()
								}}
							>
								Contact Us...
							</button>
						</div>
						<div className="action-bar">
							<p className="action-prompt">I'd like to support Nurture's mission</p>
							<button
								className="big-button"
								onClick={() => {
									setFormData({ ...formData, subject: 'donation' })
									showDialog()
								}}
							>
								Donate...
							</button>
						</div>
					</div>
				</section>
			</main>
			<footer
				css={css`
					margin-top: 3em;
					background: #f9faff;
					padding: 2em 30px;
					${mq[0]} {
						padding: 2em 50px;
					}
				`}
			>
				<div
					css={css`
						max-width: 1000px;
						margin-left: auto;
						margin-right: auto;
					`}
				>
					<hr
						css={css`
							opacity: 0.3;
							display: none;
						`}
					/>
					<div
						css={css`
							opacity: 0.5;
						`}
					>
						<div>
							<p
								css={css`
									margin-top: 0;
								`}
							>
								Nurture is a 501(c)3 nonprofit organization ID # 93-2284210.
							</p>
						</div>
						<div>
							<p>
								<span
									css={{
										'&:before': {
											content: "attr(data-website)'\\0040'attr(data-user)",
											unicodeBidi: 'bidi-override',
											direction: 'rtl',
										},
									}}
									data-user="ofnI"
									data-website="gro.htuoyerutrun"
								/>
								<br />
								<span
									css={{
										'&:before': {
											content: 'attr(data-tel)',
											unicodeBidi: 'bidi-override',
											direction: 'rtl',
										},
									}}
									data-tel="3171-053 (514)"
								/>
							</p>
							<p>
								Nurture
								<br />
								650 Laguna St
								<br />
								San Francisco, CA 94102
							</p>
						</div>
						<div>
							Photo by <a href="https://unsplash.com/@kellysikkema">Kelly Sikkema</a>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default App
